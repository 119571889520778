@font-face {
    font-family: segoeFont;
    src: url("./assets/fonts/Segoe-Print-Font.ttf")
}



#Main {
    background-image: url("../src/assets/images/fauxBack1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
}

.h2Header {
    margin-top: .2rem !important;
    font-size: 25px !important;
    font-family: segoeFont !important;
}

.h3Description {
    font-size: 20px !important;
    width: 98%;
    font-family: segoeFont !important;
}

.fauxDescription {
    font-size: 20px !important;
    width: 98%;
    font-family: segoeFont !important;
}

#Hero {
    background-image: url("../src/assets/images/hero.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   
    
  }

  








/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .homeGrid {
        overflow: hidden !important;
        margin: none !important;
    }

    .homeGrid img {
        width: 300px !important;
        height: 100% !important;
        margin: none !important;
    }
    .divider {
        width: 90% !important;
    }
    .heroContainer {
        padding-top: 15%;
        top: 100px !important;
        width: 100% !important;
        height: 10vh !important;
    }
    .heroImage {
        padding-top: 5%;
        width: 40% !important;
        height: 30% !important;
    }
    .heroFont {
        font-size: 15px !important;
    }
    h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1.6rem !important; 
    }

    h3 {
        font-size:.9rem !important;
    }

    h4 {
        font-size: 1.5rem !important;
    }

    p {
        font-size: 1rem !important;
    }
    .h2Header {
        font-size: 25px !important;
    }
    
    .h3Description {
        font-size: 15px !important;
    }

    #Hero {
        background-size: contain;
      }

      .navDivider {
        display: none !important;
    }

    .imageMargin {
        margin: 0% !important;
    }

    .reverseColumn {
        flex-direction: column-reverse !important;
    }

   .footerText {
        font-size: 10px !important;
        width: 100% !important; 
    }
    .contactIconText {
        transform: scale(.7);
    }

    header {
        font-size: 2.5rem !important;
    }

   .contacth2 {
        font-size: 1rem !important;
    }

    .contacth3 {
        font-size: .5rem !important;
    }
}
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 480px) and (max-width: 767px) {
    .heroContainer {
        margin-top: 5%;
        top: 50px !important;

    }

    #Hero {
        background-size: contain;
      }

    .heroImage {
        width: 30vw !important;
        height: 30% !important;
    }

    .heroFont {
        font-size: 15px !important;
    }

    .homeGrid {
        overflow: hidden !important;
        margin: none !important;
    }
    .homeGrid img {
        width: 250px !important;
        height: 250px !important;
        margin: none !important;
    }

    h2 {
        font-size: 1.5rem !important;
    }

    h3 {
        font-size: 1.2rem !important;
    }

    h4 {
        font-size: 1.5rem !important;
    }

   p {
    font-size: 11px !important;
   }

   button {
    font-size: .7rem !important;
   }

   .hamburger {
    font-size: 1.5rem !important;
   }

   .heroContainer {
    padding-top: 15%;
    top: 100px !important;
    width: 100vw !important;
    height:10vh !important;
}

.navDivider {
    display: none !important;
}

.reverseColumn {
    flex-direction: column-reverse !important;
}

.imageMargin {
    margin: 0% !important;
}

  

    
}



/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {

    h2{
        font-size: 2rem !important;
    }

    h3 {
        font-size: 1rem !important;
    }

    h4 {
        font-size: 2rem !important;
    }

    p {
        font-size: 15px !important;
    }

    .homeGrid  img {
        width: 300px !important;
        height: 300px !important;
        margin: none !important;
    }

    .navLink {
        font-size: .9rem !important;
        padding: 2px !important;
    }

    .heroContainer {
        transform: scale(.8);
        margin-top: 5%;
         width: 100% !important;
        height: 35vh !important;
    }

    #Hero h1 {
        font-size: 30px !important;
    }

    #Hero {
        background-size: contain;
      }

   
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
    .navLink {
        font-size: .9rem !important;
    }

    .heroContainer {
        transform: scale(.7);
        margin-top: 5%;
        top: 30px;
        width: 100% !important;
        height: 45vh !important;
    }

    .heroImage {
        width: 40% !important;
        height: 30% !important;
        padding-top: 8%;
    }

    .heroFont {
        font-size: 1.8em !important;
    }

    h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1.6rem !important;
    }

    h3 {
        font-size: 1.2rem !important;
    }

    h4 {
        font-size: 1.5rem !important;
    }

    p {
        font-size: 1rem !important;
    }

    .homeGridMiddle {
        padding-right: 10% !important;
    }

    #Hero {
        background-size: contain !important;
      }


}

/* Media Query for Large screens */
@media (min-width: 1281px) {

    .navLink {
        font-size: .9rem !important;
        padding: 0px !important;
    }

    .heroContainer {
        transform: scale(.7);
        margin-top: 5%;
        top: 30px;
        width: 70% !important;
        height: 40vh !important;
    }

    .heroImage {
        padding-top: 8%;
        width: 35% !important;
        height: 30% !important;
    }

    .heroFont {
        font-size: 1.9em !important;
    }

    h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1.5rem !important;
    }

    h3 {
        font-size: 1.1rem !important;
    }

    h4 {
        font-size: 1.9rem !important;
    }

    p {
        font-size: 1.2rem !important;
    }

    .homeMiddleImage {
        margin-left: -80px !important;
    }

    .homeMiddleText {
        margin-left: -85px !important;
    }

    #Hero {
        background-size: contain !important;
      }

}

@media (min-width: 1481px) {

    .navLink {
        font-size: 1rem !important;
        padding: 0px !important;
    }

    .heroContainer {
        transform: scale(.7);
        margin-top: 5%;
        top: 30px;
        width: 70% !important;
        height: 40vh !important;
    }

    .heroImage {
        padding-top: 5%;
        width: 40% !important;
        height: 35% !important;
    }

    .heroFont {
        font-size: 1.9em !important;
    }

    h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1.6rem !important;
    }

    h3 {
        font-size: 1.2rem !important;
    }

    h4 {
        font-size: 2rem !important;
    }

    p {
        font-size: 1.3rem !important;
    }
    #Hero {
        background-size: contain !important;
      }


}

@media (min-width: 1681px) {
    .navLink {
        font-size: 1rem !important;
        padding: 0px !important;
    }

    .heroContainer {
        transform: scale(.7);
        margin-top: 5%;
        top: 30px;
        width: 80% !important;
        height: 60vh !important;
    }

    .heroImage {
        width: 40% !important;
        height: 30% !important;
        padding-top: 5%;
    }

    .heroFont {
        font-size: 3rem !important;
    }

    h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1.6rem !important;
    }

    h3 {
        font-size: 1.2rem !important;
    }

    h4 {
        font-size: 1.5rem !important;
    }

    p {
        font-size: 1rem !important;
    }
    #Hero {
        background-size: contain !important;
      }

}

@media (min-width: 1881px) {
    .navLink {
        font-size: 1rem !important;
    }

    .heroContainer {
        transform: scale(.7);
        margin-top: 5%;
        top: 30px;
        width: 80% !important;
        height: 60vh !important;
    }

    .heroImage {
        width: 40% !important;
        height: 30% !important;
        padding-top: 5%;
    }

    .heroFont {
        font-size: 3rem !important;
    }

    h1 {
        font-size: 1.9em;
    }

    h2 {
        font-size: 2.2rem !important;
    }

    h3 {
        font-size: 1.6rem !important;
    }

    h4 {
        font-size: 2rem !important;
    }

    p {
        font-size: 1.4rem !important;
    }
    #Hero {
        background-size: contain !important;
      }


}

@media (min-width: 2100px) {
    body {
        background-color: white;
    }

    .navLink {
        font-size: 1.3rem !important;
    }

    .heroContainer {
        margin-top: 5%;
        top: 30px;
     
    }

    .heroImage {
        width: 40% !important;
        height: 35% !important;
    }

    .heroFont {
        font-size: 3.5rem !important;
    }

    #Hero {
        background-size: contain !important;
      }

    #Contact h2 {
        font-size: 30px !important;
    }


}