/* NAVBAR ------------------------------------------------------------------- */

.hamburger {
  display: none ;
  cursor: pointer;
  font-size: 2em;
  }

  .navLink {
    color: rgb(0, 0, 0) !important;
    font-weight: bold;

  }
  
  @media (max-width: 768px) {
    .hamburger {
      display: block;
      color: hsl(274deg 53% 63%);
      background-color: transparent;
      border: aliceblue;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1 !important;
    }
  
    .navbar-collapse {
      height: 100vh;
      width: 100vw;
      background: hsl(274deg 53% 63%);
      position: fixed;
      top: 0;
      right: 0;
      z-index: 9999;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease-in-out;
      transform: translateX(100%);
    }
  
    .navLink {
      color: white !important;
      font-size: 30px;
      margin: 0.3em 0;
      text-align: center;
      padding: 10px 5px !important;
      font-weight: bold;

    }
  
    .show {
      transform: translateX(0);
    }
  
    .navbar.open {
      height: 100vh;
      width: 100vw;
    }
  }


  
  






/* -----------------------------------------------------------------------------NAVBAR */